interface LabelData {
    image: string | null;
    label_id: number | null;
    name: string | null;
    type: string | null;
}

export function cleanLabelData<T extends LabelData>(labels: (T | null)[] | null) {
    if (!labels) return [];

    return labels.map((label) => {
        if (!label)
            return {
                id: null,
                image: null,
                name: null,
                type: null,
            };

        return {
            id: label.label_id,
            image: label.image,
            name: label.name,
            type: label.type,
        };
    });
}
