import { productDetail_productDetail_items } from 'src/queries/__generated__/productDetail';
import { getDeliveryType, DeliveryType } from 'src/components/ProductDetail/utils/product-detail.types';

export enum ProductAvailability {
    BackOrder = 'BackOrder',
    Discontinued = 'Discontinued',
    InStock = 'InStock',
    InStoreOnly = 'InStoreOnly',
    LimitedAvailability = 'LimitedAvailability',
    MadeToOrder = 'MadeToOrder',
    OnlineOnly = 'OnlineOnly',
    OutOfStock = 'OutOfStock',
    PreOrder = 'PreOrder',
    PreSale = 'PreSale',
    Reserved = 'Reserved',
    SoldOut = 'SoldOut',
}

export interface ProductAttrs {
    click_collect: boolean;
    delivery: boolean;
    deliveryType: DeliveryType | undefined;
    free_returns: boolean;
    free_shipping: boolean;
    isInStock?: boolean;
    isInStoreOnly?: boolean;
    isOnlineAndInStore?: boolean;
    isOnlineOnly?: boolean;
    isOutOfStock?: boolean;
    productSchemaAvailability?: ProductAvailability;
}

export const EMPTY_ATTRS = {
    click_collect: false,
    delivery: true,
    deliveryType: undefined,
    free_returns: false,
    free_shipping: false,
};

export function productAttrsFromQuery<T extends productDetail_productDetail_items>(first: T | undefined): ProductAttrs {
    if (!first) {
        return EMPTY_ATTRS;
    }

    if (!first.woodies) {
        console.warn('`woodies` attribute does not exist');
        return EMPTY_ATTRS;
    }

    const click_collect = first.click_and_collected === 1;
    const delivery =
        first.home_delivery === 1 || first.is_available_hd_delivery_centre === 1 || first.delivery_type === 3;
    const deliveryType = getDeliveryType(first.delivery_type);
    const free_returns = Boolean(first.woodies.free_returns);
    const free_shipping = Boolean(first.woodies.free_shipping);

    const isInStock = click_collect || delivery;
    const isInStoreOnly = click_collect && !delivery;
    const isOnlineAndInStore = click_collect && delivery;
    const isOnlineOnly = !click_collect && delivery;
    const isOutOfStock = !click_collect && !delivery;

    let productSchemaAvailability = ProductAvailability.InStock;

    if (isOnlineOnly) {
        productSchemaAvailability = ProductAvailability.OnlineOnly;
    } else if (isInStoreOnly) {
        productSchemaAvailability = ProductAvailability.InStoreOnly;
    } else if (isOutOfStock) {
        productSchemaAvailability = ProductAvailability.OutOfStock;
    }

    /**
     * Where delivery_type can be equal to 1, 2, or 3
     * 1 = Home Delivery
     * 2 = Delivery Centre
     * 3 = Drop-ship delivery
     */
    return {
        click_collect,
        delivery,
        deliveryType,
        free_returns,
        free_shipping,
        isInStock,
        isInStoreOnly,
        isOnlineAndInStore,
        isOnlineOnly,
        isOutOfStock,
        productSchemaAvailability,
    };
}
