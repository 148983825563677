/**
 * This is the type to be used in the UI
 */
import { productDetail_productDetail_items_breadcrumbs } from 'src/queries/__generated__/productDetail';

export interface Breadcrumb {
    id: number | string;
    name: string;
    url_key: string;
    url_path: string[];
    url?: string;
}

export function cleanBreadcrumbs<T extends GqlBreadcrumb>(breadcrumbs: (T | null)[] | null): Breadcrumb[] {
    if (!breadcrumbs) return [];
    const paths = breadcrumbs.map((x) => {
        if (!x || !x.category_url_key) return 'unknown';
        return x.category_url_key;
    });
    return breadcrumbs.map((item, index) => {
        if (!item) {
            return {
                name: 'unknown',
                url_key: 'unknown',
                url_path: ['unknown'],
                id: index,
                url: '',
            };
        }
        return {
            name: item!.category_name!,
            url_key: item!.category_url_key!,
            url_path: paths.slice(0, index).filter(Boolean),
            id: item.category_id || index,
            url: '/' + item.category_url_path,
        };
    });
}

type GqlBreadcrumb = productDetail_productDetail_items_breadcrumbs;
